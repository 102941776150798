import * as echarts from "echarts";

export const aiCoachTab = (color, rating) => {
  return {
    coach: null,
    chart: null,
    chartItemBorderRadius: 85,
    fontSize: 7,
    fontWeight: 400,
    rating: undefined,
    color: "gray",
    colorMapping: {
      "bg-emerald-50": "#f0fdf4",
      "bg-emerald-600": "#047857",
      "bg-emerald-200": "#c6f6d5",
      "bg-red-50": "#fef2f2",
      "bg-red-600": "#dc2626",
      "bg-red-200": "#fecaca",
      "bg-amber-50": "#fffbeb",
      "bg-amber-600": "#d97706",
      "bg-amber-200": "#fde68a",
      "bg-gray-600": "#4b5563",
      "bg-gray-200": "#e5e7eb",
      "bg-gray-50": "#f9fafb",
    },

    initChart() {
      if (this.coach && this.chart) {
        let myChart = echarts.init(this.chart, null, {
          renderer: "svg",
        });

        const data = [];

        for (const index of [...Array(10).keys()]) {
          const color = this.getChartItemColor(index * 10);

          data.push({
            value: this.rating >= 0 ? this.rating : false,
            itemStyle: { color },
          });
        }
        const borderRadius = this.chartItemBorderRadius + "%";
        let option = {
          legend: {
            show: false,
          },
          series: [
            {
              type: "pie",
              radius: [borderRadius, "100%"],
              padAngle: 3,
              responsives: 3,
              avoidLabelOverlap: false,
              maintainAspectRatio: false,
              silent: true,
              itemStyle: {
                borderRadius: 50,
              },
              label: {
                show: true,
                position: "center",
                fontSize: this.fontSize,
                fontWeight: this.fontWeight,
                color: this.colorMapping[`bg-${this.color}-600`],
                lineHeight: 20,
                width: 200,
                formatter: function (d) {
                  if (d.value !== false && d.value >= 0) {
                    return `{fontSizeXL|${d.value}}{ratingEmptySpaceStyle| }{fontSizeXS|%}`;
                  }
                  return `{onlyPercent|%}`;
                },
                rich: {
                  ratingEmptySpaceStyle: {
                    fontSize: 3,
                  },
                  emptySpaceStyle: {
                    fontSize: 7,
                  },
                  fontSizeXL: {
                    fontSize: 26,
                  },
                  fontSizeXS: {
                    fontSize: 10,
                  },
                  onlyPercent: {
                    fontSize: 26,
                    fontWeight: 900,
                  },
                },
              },
              labelLine: {
                show: false,
              },
              data: data,
            },
          ],
        };
        option && myChart.setOption(option);
      }
    },
    init() {
      this.coach = document.getElementById("ai_coach_tab");
      this.chart = document.getElementById("ai_coach_chart");
      this.content = document.getElementById("ai_coach_content");
      this.color = color || this.color;
      if (this.content) {
        this.content.style.backgroundColor =
          this.colorMapping[`bg-${this.color}-50`];
        this.content.style.borderColor =
          this.colorMapping[`bg-${this.color}-200`];
      }
      this.rating = rating;
      this.initChart();
    },

    getChartItemColor(chartOrder) {
      const colorClass =
        chartOrder < this.rating
          ? `bg-${this.color}-600`
          : `bg-${this.color}-200`;
      return this.colorMapping[colorClass];
    },
  };
};
