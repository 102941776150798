/** @type {import('tailwindcss').Config} */
module.exports = {
  content: ["../templates/**/*.{html,js}"],
  theme: {
    extend: {
      content: {
        "editable-placeholder": "attr(data-placeholder)",
      },
      flex: {
        2: "2 2 0%",
      },
      boxShadow: {
        xs: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
        sm: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
        lg: "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
      },
      fontSize: {
        xxs: "0.625rem",
        xs: "0.75rem",
      },
      lineHeight: {
        4.5: "1.125rem",
      },
      fontFamily: {
        sans: [
          "ui-sans-serif",
          "system-ui",
          "-apple-system",
          "BlinkMacSystemFont",
          "Segoe UI",
          "Roboto",
          "Helvetica Neue",
          "Arial",
          "Noto Sans",
          "sans-serif",
          "Apple Color Emoji",
          "Segoe UI Emoji",
          "Segoe UI Symbol",
          "Noto Color Emoji",
        ],
        inter: ["Inter", "sans-serif"],
      },
      colors: {
        gray: {
          25: "#FCFCFD",
          50: "#F9FAFB",
          100: "#F2F4F7",
          200: "#E4E7EC",
          300: "#D0D5DD",
          400: "#98A2B3",
          500: "#667085",
          600: "#475467",
          700: "#344054",
          800: "#1D2939",
          900: "#101828",
          950: "#0C111D",
        },
        primary: {
          25: "#F5F8FF",
          50: "#eff9ff",
          100: "#D1E0FF",
          200: "#b6e8ff",
          300: "#75d7ff",
          400: "#2cc4ff",
          500: "#2970FF",
          600: "#1570EF",
          700: "#006dab",
          800: "#005c8d",
          900: "#064d74",
          950: "#04314d",
        },
        secondary: {
          50: "#fdfde8",
          100: "#f7f9ce",
          200: "#eff4a2",
          300: "#e1eb6b",
          400: "#cddc39",
          500: "#b1c31f",
          600: "#8a9b15",
          700: "#687615",
          800: "#535e16",
          900: "#465017",
          950: "#252c07",
        },
        "primary-blue": {
          25: "#F5FAFF",
          50: "#EFF8FF",
          100: "#D1E9FF",
          200: "#B2DDFF",
          300: "#84CAFF",
          400: "#84CAFF",
          500: "#2E90FA",
          600: "#1570EF",
          700: "#175CD3",
          800: "#1849A9",
          900: "#194185",
        },
        danger: "#FF6347",
        red: {
          50: "#fff3f1",
          100: "#ffe4df",
          200: "#ffcec5",
          300: "#ffac9d",
          400: "#ff7c64",
          500: "#F04438",
          600: "#ed3615",
          700: "#c8290d",
          800: "#a5260f",
          900: "#882614",
          950: "#4b0f04",
        },
        warning: {
          25: "#FFFCF5",
          50: "#FFFAEB",
          100: "#FEF0C7",
          200: "#FEDF89",
          300: "#FEC84B",
          400: "#FDB022",
          500: "#F79009",
          600: "#DC6803",
          700: "#B54708",
          800: "#93370D",
          900: "#7A2E0E",
        },
        success: {
          25: "#F6FEF9",
          50: "#ECFDF3",
          100: "#D1FADF",
          200: "#A6F4C5",
          300: "#6CE9A6",
          400: "#32D583",
          500: "#17B26A",
          600: "#039855",
          700: "#027A48",
          800: "#05603A",
          900: "#054F31",
        },
        error: {
          900: "#7A271A",
          800: "#912018",
          700: "#B42318",
          600: "#D92D20",
          500: "#F04438",
          400: "#F97066",
          300: "#FDA29B",
          200: "#FECDCA",
          100: "#FEE4E2",
          50: "#FEF3F2",
          25: "#FFFBFA",
        },
        "blue-gray": {
          25: "#FCFCFD",
          50: "#F8F9FC",
          100: "#EAECF5",
          200: "#D5D9EB",
          300: "#AFB5D9",
          400: "#717BBC",
          500: "#4E5BA6",
          600: "#3E4784",
          700: "#363F72",
          800: "#293056",
          900: "#101323",
        },
        "blue-light": {
          25: "#F5FBFF",
          50: "#F0F9FF",
          100: "#E0F2FE",
          200: "#B9E6FE",
          300: "#7CD4FD",
          400: "#36BFFA",
          500: "#0BA5EC",
          600: "#0086C9",
          700: "#026AA2",
          800: "#065986",
          900: "#0B4A6F",
        },
        blue: {
          25: "#F5FAFF",
          50: "#EFF8FF",
          100: "#D1E9FF",
          200: "#B2DDFF",
          300: "#84CAFF",
          400: "#53B1FD",
          500: "#2E90FA",
          600: "#1570EF",
          700: "#175CD3",
          800: "#1849A9",
          900: "#194185",
        },
        indigo: {
          25: "#F5F8FF",
          50: "#EEF4FF",
          100: "#E0EAFF",
          200: "#C7D7FE",
          300: "#A4BCFD",
          400: "#8098F9",
          500: "#6172F3",
          600: "#444CE7",
          700: "#3538CD",
          800: "#2D31A6",
          900: "#2D3282",
        },
        purple: {
          25: "#FAFAFF",
          50: "#F4F3FF",
          100: "#EBE9FE",
          200: "#D9D6FE",
          300: "#BDB4FE",
          400: "#9B8AFB",
          500: "#7A5AF8",
          600: "#6938EF",
          700: "#5925DC",
          800: "#4A1FB8",
          900: "#3E1C96",
        },
        pink: {
          25: "#FEF6FB",
          50: "#FDF2FA",
          100: "#FCE7F6",
          200: "#FCCEEE",
          300: "#FAA7E0",
          400: "#F670C7",
          500: "#EE46BC",
          600: "#DD2590",
          700: "#C11574",
          800: "#9E165F",
          900: "#851651",
        },
        rose: {
          25: "#FFF5F6",
          50: "#FFF1F3",
          100: "#FFE4E8",
          200: "#FECDD6",
          300: "#FEA3B4",
          400: "#FD6F8E",
          500: "#F63D68",
          600: "#E31B54",
          700: "#C01048",
          800: "#A11043",
          900: "#89123E",
        },
        orange: {
          25: "#FFFAF5",
          50: "#FFF6ED",
          100: "#FFEAD5",
          200: "#FDDCAB",
          300: "#FEB273",
          400: "#FD853A",
          500: "#FB6514",
          600: "#EC4A0A",
          700: "#C4320A",
          800: "#9C2A10",
          900: "#7E2410",
        },
      },
    },
  },
  plugins: [require("@tailwindcss/forms")],
  safelist: [
    {
      pattern:
        /(bg|text)-(gray|primary|error|warning|success|blue-gray|blue-light|blue|indigo|purple|pink|rose|orange)-(25|50|100|200|300|400|500|600|700|800|900)/,
      variants: ["hover", "focus"],
    },
  ],
};
