import Alpine from "alpinejs";
// For some reason it is needed to import htmx from another file in order to be able to add htmx to window
import htmx from "./htmx";
import { callVideoPlayer } from "./video-player";
import { speechBlockCard, transcriptTab } from "./transcript-tab";
import { aiSummaryTab } from "./ai-summary-tab";
import { aiCoachTab } from "./ai-coach-tab";
import {
  dateFormatter,
  timeAgo,
  dateFormatterDDMMYYYY,
  dateFormatterTimeOnly,
} from "./date-formatter";
import { currencyFormatter } from "./currency-formatter";
import { shareVideoModal } from "./share-video-modal";
import { sharedVideoPlayer } from "./shared-video-player";
import { createSnippet } from "./create-snippet";
import { callVideoTimeline } from "./video-timeline";
import focus from "@alpinejs/focus";
import { displayChart } from "./chart";
import { integrationForm } from "./integration-form";
import { integrations } from "./integrations";
import { myProfile } from "./my-profile";
import { multiSelect } from "./multi-select";
import { globalData } from "./global";
import { richTextArea } from "./rich-text-area";
import localStorageHelper from "./local-storage-helper";

window.Alpine = Alpine;
Alpine.plugin(focus);

Alpine.data("globalData", globalData);
Alpine.data("callVideoPlayer", callVideoPlayer);
Alpine.data("aiSummaryTab", aiSummaryTab);
Alpine.data("transcriptTab", transcriptTab);
Alpine.data("speechBlockCard", speechBlockCard);
Alpine.data("aiCoachTab", aiCoachTab);
Alpine.data("dateFormatter", dateFormatter);
Alpine.data("timeAgo", timeAgo);
Alpine.data("dateFormatterDDMMYYYY", dateFormatterDDMMYYYY);
Alpine.data("dateFormatterTimeOnly", dateFormatterTimeOnly);
Alpine.data("currencyFormatter", currencyFormatter);
Alpine.data("shareVideoModal", shareVideoModal);
Alpine.data("sharedVideoPlayer", sharedVideoPlayer);
Alpine.data("createSnippet", createSnippet);
Alpine.data("callVideoTimeline", callVideoTimeline);
Alpine.data("displayChart", displayChart);
Alpine.data("integrations", integrations);
Alpine.data("integrationForm", integrationForm);
Alpine.data("myProfile", myProfile);
Alpine.data("multiSelect", multiSelect);
Alpine.data("richTextArea", richTextArea);

Alpine.start();

document.addEventListener("htmx:configRequest", (event) => {
  event.detail.headers["Content-Type"] = "application/json";
});

htmx.on("showNotification", (event) => {
  localStorage.setItem(
    "notification",
    JSON.stringify({
      notificationType: event.detail.notificationType,
      message: event.detail.message,
      content: event.detail.content,
    }),
  );
  if (event.detail.redirectUrl) {
    location.href = event.detail.redirectUrl;
  } else if (event.detail.goBack) {
    location.href = document.referrer;
  } else if (event.detail.refreshPage) {
    location.reload();
  }
});

document.body.addEventListener("htmx:afterProcessNode", (evt) => {
  let storedNotificationStr = localStorage.getItem("notification");
  if (storedNotificationStr) {
    let storedNotification = JSON.parse(storedNotificationStr);
    localStorage.removeItem("notification");
    htmx.ajax("POST", "/notification", {
      target: "#settings_notification",
      swap: "outerHTML",
      values: {
        notificationType: storedNotification.notificationType,
        message: storedNotification.message,
        content: storedNotification.content,
      },
    });
  }
});

document.body.addEventListener("htmx:afterOnLoad", function () {
  handleAuthCallback();
});

function handleAuthCallback() {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);

  if (params.has("code")) {
    let authCallbackUrl = undefined;
    if (url.pathname.includes("salesforce")) {
      authCallbackUrl = "/settings/form/integrations/salesforce-auth-callback";
    } else if (url.pathname.includes("hubspot")) {
      authCallbackUrl = "/settings/form/integrations/hubspot-auth-callback";
    } else if (url.pathname.includes("google")) {
      authCallbackUrl =
        "/settings/my-profile/my-calendars/connect/google_calendar/auth-callback";
    } else if (url.pathname.includes("outlook")) {
      authCallbackUrl =
        "/settings/my-profile/my-calendars/connect/outlook_calendar/auth-callback";
    }

    const code = params.get("code");
    localStorageHelper.removeQueryParam("code");

    htmx.ajax("POST", authCallbackUrl, {
      values: {
        code: params.get("code"),
      },
    });
  }
}

htmx.defineExtension("my-ext", {
  encodeParameters: function (xhr, parameters, elt) {
    console.log(parameters);
    console.log(elt);
    xhr.overrideMimeType("text/json");
    return JSON.stringify(parameters);
  },
});
