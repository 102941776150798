export const globalData = () => {
  return {
    modalOpen: false,
    slideOverOpen: false,
    skippedMissingCalendarConnectionAlert: false,

    init() {
      const url = new URL(window.location.href);
      let calendarConnectionPage = url.pathname.includes("my-calendars");
      if (!calendarConnectionPage) {
        this.checkCalendarConnection().await;
      }
    },

    async checkCalendarConnection() {
      try {
        const response = await fetch("/check-calendar-connection");
        if (response.ok) {
          try {
            let calendarConnection = await response.json();

            if (!calendarConnection.isValid) {
              this.skippedMissingCalendarConnectionAlert =
                calendarConnection.skipUserPorompting;
              if (!this.skippedMissingCalendarConnectionAlert) {
                htmx
                  .ajax("GET", "/missing-calendar-connection", {
                    target: "#main-modal-content",
                    swap: "innerHTML",
                  })
                  .then(() => {
                    this.modalOpen = true;
                  });
              }
            } else {
              this.skippedMissingCalendarConnectionAlert = false;
            }
          } catch (error) {
            console.error(
              "Error checking the calendar connection validity",
              error,
            );
          }
        } else {
          console.error("Request failed with status:", response.status);
        }
      } catch (error) {
        console.error(error);
      }
    },
  };
};
