const localStorageHelper = () => {
  return {
    setWithExpiryDate(key, value, timeToLive) {
      const now = new Date();

      const item = {
        value: value,
        expiryDate: now.getTime() + timeToLive,
      };

      localStorage.setItem(key, JSON.stringify(item));
    },

    getItemIfNotExpired(key) {
      const itemStr = localStorage.getItem(key);

      if (!itemStr) {
        return null;
      }

      const item = JSON.parse(itemStr);
      const now = new Date();

      if (now.getTime() > item.expiryDate) {
        localStorage.removeItem(key);
        return null;
      }

      return item.value;
    },

    removeQueryParam(param) {
      const url = new URL(window.location.href); // Create URL object
      url.searchParams.delete(param); // Remove the query parameter
      window.history.pushState({}, "", url); // Update the URL in the browser without reloading
    },
  };
};

export default localStorageHelper();
