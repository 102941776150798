export const richTextArea = (content, choices, preselectedChoices) => {
  return {
    content: content,
    contentToBeReplaced: "",
    allChoices: choices,
    choices: { accString: "", leftChoices: choices },
    areChoisesOpen: false,
    position: { x: 0, y: 0 },
    selectedChoiceIndex: undefined,
    selectedChoices: [],
    init() {
      this.$refs.input.innerHTML = this.content;
      if (preselectedChoices) {
        this.selectedChoices = preselectedChoices;
      }
      const range = document.createRange();
      const selection = window.getSelection();
      selection.removeAllRanges();
      range.selectNodeContents(this.$refs.input);
      range.collapse(false);
      selection.addRange(range);
    },
    itemClicked(item, index) {
      const keyPairs = [];
      let content = this.content;
      for (let i = 0; i < this.selectedChoices.length; i++) {
        keyPairs.push({
          text: "@" + this.selectedChoices[i].text,
          tempString: `[replace${i}]`,
        });
        content = content.replace(
          "@" + this.selectedChoices[i].text,
          `[replace${i}]`,
        );
      }
      this.selectedChoices.push(this.choices.leftChoices[index]);
      this.areChoisesOpen = false;
      this.contentToBeReplaced = content.replace(
        "@" + this.choices.accString,
        "@" + this.choices.leftChoices[index].text,
      );

      for (let i = 0; i < keyPairs.length; i++) {
        this.contentToBeReplaced = this.contentToBeReplaced.replace(
          `[replace${i}]`,
          "@" + this.selectedChoices[i].text,
        );
      }

      this.content = this.contentToBeReplaced;
      this.$nextTick(() => {
        this.$refs.input.innerHTML = this.contentToBeReplaced;
        this.content = this.contentToBeReplaced;
        const range = document.createRange();
        const selection = window.getSelection();

        selection.removeAllRanges();

        range.selectNodeContents(this.$refs.input);
        range.collapse(false);
        selection.addRange(range);
      });
      this.areChoisesOpen = false;
      this.choices = { accString: "", leftChoices: choices };
      this.selectedChoiceIndex = 0;
    },
    keyDown(event) {
      const key = event.key;
      if (key === "ArrowUp") {
        if (this.selectedChoiceIndex > 0) {
          this.selectedChoiceIndex -= 1;
        }
      }
      if (key === "ArrowDown") {
        if (this.selectedChoiceIndex < this.choices.leftChoices.length - 1) {
          this.selectedChoiceIndex += 1;
        }
      }
      if (key === "Enter") {
        const keyPairs = [];
        let content = this.content;
        for (let i = 0; i < this.selectedChoices.length; i++) {
          keyPairs.push({
            text: "@" + this.selectedChoices[i].text,
            tempString: `[replace${i}]`,
          });
          content = content.replace(
            "@" + this.selectedChoices[i].text,
            `[replace${i}]`,
          );
        }
        this.selectedChoices.push(
          this.choices.leftChoices[this.selectedChoiceIndex],
        );
        this.areChoisesOpen = false;
        this.contentToBeReplaced = content.replace(
          "@" + this.choices.accString,
          "@" + this.choices.leftChoices[this.selectedChoiceIndex].text,
        );

        for (let i = 0; i < keyPairs.length; i++) {
          this.contentToBeReplaced = this.contentToBeReplaced.replace(
            `[replace${i}]`,
            "@" + this.selectedChoices[i].text,
          );
        }

        this.content = this.contentToBeReplaced;
        this.$nextTick(() => {
          this.$refs.input.innerHTML = this.contentToBeReplaced;
          this.content = this.contentToBeReplaced;
          const range = document.createRange();
          const selection = window.getSelection();

          selection.removeAllRanges();

          range.selectNodeContents(this.$refs.input);
          range.collapse(false);
          selection.addRange(range);
        });
        this.areChoisesOpen = true;
        this.choices = { accString: "", leftChoices: choices };
        this.selectedChoiceIndex = 0;
      }
    },
    processContent(event) {
      if (event.data === "@") {
        this.areChoisesOpen = true;
        this.choices = { accString: "", leftChoices: choices };
        this.selectedChoiceIndex = 0;
      } else if (event.data === null) {
        const updatedAccString = this.choices.accString.slice(0, -1);
        const filteredChoices = this.allChoices.filter((c) =>
          c.text.includes(updatedAccString),
        );
        this.choices = {
          accString: updatedAccString,
          leftChoices: filteredChoices,
        };
        if (updatedAccString === "") {
          this.areChoisesOpen = false;
        }
      } else {
        this.selectedChoiceIndex = 0;
        const updatedAccString = this.choices.accString + event.data;
        const filteredChoices = this.allChoices.filter((c) =>
          c.text.toLowerCase().includes(updatedAccString.toLowerCase()),
        );
        this.choices = {
          accString: updatedAccString,
          leftChoices: filteredChoices,
        };
        if (filteredChoices.length === 0) {
          this.areChoisesOpen = false;
        }
      }
    },
    updateCursorPosition(event) {
      const selection = window.getSelection();
      if (selection.rangeCount === 0) return;
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();

      const cursorPosition = {
        x: rect.left,
        y: rect.top,
      };
      this.position = { x: cursorPosition.x, y: cursorPosition.y };
    },
  };
};
